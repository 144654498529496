globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"prod-108e8d5-80275c0d-f521-4643-8227-41259bfb11de"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from "@sentry/nextjs";

const SENTRY_EXCLUDED_PATHS = ["/health-check"];

if (process.env.sentryDsn) {
  Sentry.init({
    tunnel: process.env.sentryTunnel,
    dsn: process.env.sentryDsn,
    environment: process.env.environment,
    tracesSampler: (samplingContext) => {
      const path = samplingContext.location?.pathname;
      if (SENTRY_EXCLUDED_PATHS.includes(path)) {
        return 0.0;
      }

      return 0.2;
    },
    beforeSendTransaction(event) {
      if (event.user) {
        delete event.user.ip_address;
        delete event.user.email;
      }
      return event;
    },
    beforeSend(event) {
      if (event.user) {
        delete event.user.ip_address;
        delete event.user.email;
      }
      return event;
    },
    ignoreErrors: [
      /.*ReaderArticleFinderJS\.adoptableArticle.*/,
      "this.Eg.close is not a function",
    ],
  });
}
